init_checkbox_triggers = (type) ->
  $("#select-all-#{type}-files").click ->
    $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]").prop("checked", this.checked)
    toggle_destroy_button(type)
    toggle_download_button(type)
  $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]").change ->
    toggle_destroy_button(type)
    toggle_download_button(type)

toggle_destroy_button = (type) ->
  if $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]:checked").length
    $(".destroy-selection[name=\"#{type}\"]").removeClass('disabled')
  else
    $("#select-all-#{type}-files").prop("checked", false)
    $(".destroy-selection[name=\"#{type}\"]").addClass('disabled')

toggle_download_button = (type) ->
  if $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]:checked").length
    $(".download-selection[name=\"#{type}\"]").removeClass('disabled')
  else
    $("#select-all-#{type}-files").prop("checked", false)
    $(".download-selection[name=\"#{type}\"]").addClass('disabled')

update_job_progress = (job_id) ->
  $('#upload-btn').hide()
  $('#progress-bar').show()
  $.get("/job_progress?job_id=#{job_id}")
    .done( (response) ->
      percent = response.pct_complete || 10
      message = response.message || 'Scan'
      $('#job-progress')[0].setAttribute('style', "width:#{percent}%")
      $('#job-progress')[0].textContent = "#{percent}% | #{message}"
      if ["complete", "failed", "interrupted", "", false].includes response.status
        window.location = location.href.split('?')[0]
      else
        setTimeout(update_job_progress, 1500, job_id)
    )

$(document).on 'turbolinks:load', ->
  $('.destroy-selection').click (event) ->
    event.preventDefault
    referrer = location.href
    type = this.attributes.name.value
    selection = $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]:checked")
    if selection.length && confirm("Wollen Sie #{selection.length} ausgewählte Dateien unwiderruflich löschen?")
      ids = []
      for box in selection
        ids.push box.value
      $.ajax(url: referrer + '/destroy_selection', data: { ids: ids }, method: 'PUT')
        .done( (response) ->
          window.location = referrer
        )

  $('.download-selection').on 'click', (event) ->
    event.preventDefault
    referrer = location.origin + location.pathname
    type = this.attributes.name.value
    selection = $("input[type=\"checkbox\"][name=\"select-#{type}-file\"]:checked")
    if selection.length
      ids = []
      for box in selection
        ids.push box.value
      $.ajax(
        url: referrer + '/download_selection', 
        data: { ids: ids }, 
        method: 'PUT',
        xhrFields: { responseType: 'blob' },
        beforeSend: () ->
          $(".download-selection[name=\"#{type}\"]").addClass('disabled').html('Auswahl wird heruntergeladen')
        success: (response) -> 
          blob = new Blob([response])
          link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'files.zip'
          link.click()
          $(".download-selection[name=\"#{type}\"]").removeClass('disabled').html('<i class="fas fa-download"></i>Auswahl herunterladen')
          window.location = referrer )

  init_checkbox_triggers('admin')
  init_checkbox_triggers('user')

  job_id = location.search.substring("?job_id=".length)
  if job_id.length then update_job_progress(job_id)
