require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()


import 'bootstrap';
import '../src/javascripts/jquery_alias';
import '../src/javascripts/dnd';
import '../src/javascripts/tooltip';
import '../src/javascripts/files';
import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop'

ActiveStorageDragAndDrop.start()

require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)
